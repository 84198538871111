import { render, staticRenderFns } from "./terms-and-conditions-dialog.vue?vue&type=template&id=b1f2b1d4"
import script from "./terms-and-conditions-dialog.vue?vue&type=script&lang=js"
export * from "./terms-and-conditions-dialog.vue?vue&type=script&lang=js"
import style0 from "./terms-and-conditions-dialog.vue?vue&type=style&index=0&id=b1f2b1d4&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WLoadingState: require('/app/components/global/w-loading-state/w-loading-state.vue').default,WMessage: require('/app/components/global/w-message/w-message.vue').default,WStack: require('/app/components/global/w-stack/w-stack.vue').default,WButton: require('/app/components/global/w-button/w-button.vue').default,WDialogLayout: require('/app/components/global/w-dialog/w-dialog-layout.vue').default})
